<template>
  <dashboard-page-wrapper title="Templates">
    <template-menu activeMenu="Templates"/>
    <b-row>
      <b-col md="12">
        <card bodyClasses="p-3 pb-3">
          <div v-if="templatesLoaded">
            <div class="d-flex align-items-center">
              <base-button :disabled="templates.length >= availableTemplates"
                           type="primary"
                           @click="createTemplate"><i class="ni ni-fat-add"></i>New template
              </base-button>
              <div class="ml-3"><b>{{ templates.length }}/{{ availableTemplates }}</b></div>
            </div>
            <b-row class="mt-3">
              <b-col md="5">
                <templates-list
                  v-model="selectedTemplate"
                  :formBlocked="formBlocked"
                  :isTeamTemplate="false"
                  :templates="templates"
                  :unsavedFormData="unsavedFormData"
                  @save-template-confirmation="saveChangesConfirmation"
                />
              </b-col>
              <b-col md="7">
                <template-form
                  :canCreate="canCreate"
                  :isTeamTemplate="false"
                  :template="selectedTemplate"
                  @form-block-change="formBlockChangeHandler"
                  @update-template="updateTemplate"
                />
              </b-col>
            </b-row>
          </div>
        </card>
      </b-col>
    </b-row>
    <confirmation-modal
      ref="saveChangesModal"
      cancelBtnText="Cancel"
      confirmBtnText="Save"
      header="Save changes"
      question="You have made changes to this template, save changes before you leave?"
      @on-accept="saveTemplateChanges"
      @on-reject="changeTemplate"
    />
  </dashboard-page-wrapper>
</template>
<script>
import DashboardPageWrapper from '@/components/App/UI/DashboardPageWrapper.vue';
import {mapActions, mapGetters} from 'vuex';
import TemplateForm from './TemplateForm.vue';
import TemplatesList from './TemplatesList.vue';
import TemplateMenu from './TemplateHeaderMenu.vue';
import ConfirmationModal from '@/components/App/modals/ConfirmationModal.vue';
import {CREATE_TEMPLATE_REQUEST, FETCH_TEMPLATES_REQUEST, UPDATE_TEMPLATE_REQUEST} from '@/store/storeActions';

export default {
  components: {
    DashboardPageWrapper,
    TemplateForm,
    TemplatesList,
    ConfirmationModal,
    TemplateMenu,
  },
  data: function() {
    return {
      // availableTemplates: 15,
      selectedTemplate: null,
      formBlocked: false,
      unsavedFormData: null,
    };
  },
  computed: {
    ...mapGetters([
      'templates',
      'templatesLoaded',
      'totalTemplates',
    ]),
    availableTemplates() {
      return this.totalTemplates || 15;
    },
    canCreate() {
      return this.templates.length < this.availableTemplates;
    },
  },
  watch: {
    templates: {
      handler: function(value) {
        this.selectedTemplate = (!!value.length) ?
          (!!this.selectedTemplate) ?
            (value.find((template) => template.id === this.selectedTemplate.id)) : value[0] : null;
      },
      immediate: true,
    },
  },
  mounted() {
    if (!this.templatesLoaded) {
      this.FETCH_TEMPLATES_REQUEST();
    }
  },
  methods: {
    ...mapActions([
      FETCH_TEMPLATES_REQUEST,
      CREATE_TEMPLATE_REQUEST,
      UPDATE_TEMPLATE_REQUEST,
    ]),
    createTemplate: async function() {
      this.formBlocked && this.saveChangesConfirmation({data: {...this.unsavedFormData}});
      const payload = {
        name: 'Example: Intro',
        // eslint-disable-next-line
        message: 'Hey [FirstName], you have some great experience and I would love to speak with you about your recent job application with us. How does your schedule look?',
      };
      this.canCreate && await this.CREATE_TEMPLATE_REQUEST({payload});
      this.selectedTemplate = null;
    },
    formBlockChangeHandler: function({blocked, unsavedFormData}) {
      this.formBlocked = blocked;
      this.unsavedFormData = unsavedFormData;
    },
    saveChangesConfirmation: function(data) {
      this.$refs.saveChangesModal.openModal(data);
    },
    saveTemplateChanges: function({data, template}) {
      (!!template) && (this.selectedTemplate = template);
      (!!data && !!data.id) && this.updateTemplate({payload: {name: data.name, message: data.message}, id: data.id});
    },
    updateTemplate: function({payload, id}) {
      this.UPDATE_TEMPLATE_REQUEST({payload, id});
      this.formBlocked = false;
    },
    changeTemplate: function({template}) {
      this.selectedTemplate = template;
    },
  },
};
</script>
